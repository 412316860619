import React from 'react';
import { StyledCourseDetailsCard, StyledFeatureList, StyledFeature } from './styled';
import Icon from '../../UI/Icon';

const CourseDetailsCard = ({ categories, length, access, verified, resources, certificate }) => (
  <StyledCourseDetailsCard bordershadow="true">
    <StyledFeatureList>
      <StyledFeature>
        <Icon name="ti-video-clapper" color="#42526E" />
        <span>{length}</span>
      </StyledFeature>
      <StyledFeature>
        <Icon name="ti-folder" color="#42526E" />
        <span>{resources}</span>
      </StyledFeature>
      <StyledFeature>
        <Icon name="ti-mobile" color="#42526E" />
        <span>{access}</span>
      </StyledFeature>
      <StyledFeature>
        <Icon name="ti-check" color="#42526E" />
        <span>{verified}</span>
      </StyledFeature>
      <StyledFeature>
        <Icon name="ti-id-badge" color="#42526E" />
        <span>{certificate}</span>
      </StyledFeature>
    </StyledFeatureList>
  </StyledCourseDetailsCard>
);

export default CourseDetailsCard;
