import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ tags }) => {
  return (
    <Helmet defaultTitle={tags.title}>
      <html lang="en" />

      <meta name="description" key="description" content={tags.description} />

      {/* Begin OpenGraph Tag */}
      <meta property="og:type" key="og_type" content={tags.type} />
      {/* this meta tags helps Facebook appropriately title the page when shared within its social network */}
      <meta property="og:title" key="og_title" content={tags.title} />
      {/* Helps facebook describe the page */}
      <meta property="og:description" key="og_description" content={tags.description} />
      {/* The URL of the main page */}
      <meta property="og:url" key="og_URL" content={tags.og_URL ? tags.og_URL : tags.canonical} />
      {/* A url of an image for Facebook to use in a preview. */}
      <meta property="og:image" key="og_image" content={tags.og_image ? tags.og_image : tags.image} />
      <meta property="og:site_name" key="og_site_name" content={tags.og_site_name ? tags.og_site_name : tags.title} />
      {/* End of OpenGraph Tag */}

      {/*Twitter displayed card*/}
      {tags.twitter_site ? <meta name="twitter:card" key="twitter_card" content="summary" /> : null}

      {/*this will define the description of the post*/}
      {tags.description ? (
        <meta name="twitter:description" key="twitter_description" content={tags.description} />
      ) : null}

      {/* Twitter account */}
      {tags.twitter_site ? <meta name="twitter:site" key="twitter_site" content={tags.twitter_site} /> : null}
      {/* Name of Website */}
      {tags.twitter_domain ? <meta name="twitter:domain" key="twitter_domain" content={tags.twitter_domain} /> : null}

      {/* Image preview of shared post */}
      {tags.twitter_site ? <meta name="twitter:image:src" key="twitter_img" content={tags.image} /> : null}

      {/* End of Twitter Display card */}

      <meta name="robots" content={`${tags.robots}`} />

      {/* The URL of the canonical tags */}
      <link rel="canonical" key="canonical" href={tags.canonical} />

      <meta
        name="ahrefs-site-verification"
        content="8931267aede918a6b8c5815a4b6424a888c5cf688dfcde0f51f373d1b2ab6435"
      ></meta>
      <link rel="icon" type="image/x-icon" href="https://www.learnstability.com/favicon.ico" />
      <link rel="canonical" href="https://www.learnstability.com" />

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Heebo:300,400,500,700&display=swap"
      />
      <link rel="dns-prefetch" href="https://www.google-analytics.com" />
      <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
      <link href="https://www.google-analytics.com" rel="preconnect" crossorigin />
      <link href="https://www.googletagmanager.com" rel="preconnect" crossorigin />
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TNFRHNP');`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNFRHNP" height="0" width="0" style="display:none;visibility:hidden;"></iframe>`,
        }}
      />
    </Helmet>
  );
};

export default Meta;
