// import { concatenateStrings } from '../helpers/helpers';

export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_CONFIRMED = 'CONFIRMED';
export const STATUS_CANCELLED = 'CANCELLED';

export const defaultMetaTags = {
  title: 'Learnstability: Online Course Search - Learn Web Development Today',
  description:
    'Learn web development with Learnstability. We help developers learn more online with expert high quality videos and learning tracks designed to help you become job ready in no time. Subscribe today! ',
  canonical: 'https://www.learnstability.com',
};

export const aboutMetaTags = {
  title: 'About | Learnstability',
  description:
    'Learnstability is an all inclusive learning platform for Web Developers. We create learning tracks that allow aspiring developers to gain valuable skills that help them level up in their careers.',
  canonical: 'https://www.learnstability.com/about',
};

export const blogMetaTags = {
  title: 'Blog | Learnstability',
  description:
    'The Learnstability Blog features regular articles on Web Development, life and technology. We aim to deep dive into complex topics in a fun and friendly way to help new developers learn more online.',
  canonical: 'https://www.learnstability.com/blog',
};

export const coursesMetaTags = {
  title: 'Front End Courses | Learnstability',
  description:
    'View all of our Front End courses. Browse and filter based on price, rating or difficulty. Our course catalogue is updated regularly with new technlogies so be sure to subscribe today.',
  canonical: 'https://www.learnstability.com/courses',
};

export const loginMetaTags = {
  title: 'Login | Learnstability',
  description:
    'Login to your learnstability account to see your purchased tracks and courses or update your profile information.',
  canonical: 'https://www.learnstability.com/login',
};

export const pricingMetaTags = {
  title: 'Pricing | Learnstability',
  description:
    'Want to know how much a Learnstability track our course costs? All our course prices and regular discounts in one place. Start learning today.',
  canonical: 'https://www.learnstability.com/pricing',
};

export const privacyPolicyMetaTags = {
  title: 'Privacy Policy | Learnstability',
  description:
    'View the entire learnstability privacy policy information. Your privacy is important to us. It is Learnstabilitys policy to respect your privacy',
  canonical: 'https://www.learnstability.com/privacy-policy',
};

export const signupMetaTags = {
  title: 'Sign Up | Learnstability',
  description:
    'Sign up to an account today to access a range of different courses and learning paths provided by Learnstability. We also offer regular member only discounts and offers.',
  canonical: 'https://www.learnstability.com/signup',
};

export const termsMetaTags = {
  title: 'Terms And Conditions | Learnstability',
  description:
    'View the Learnstability terms and service. By accessing the website at https://www.lernstability.com, you are agreeing to be bound by these terms of service.',
  canonical: 'https://www.learnstability.com/terms-and-conditions',
};

export const subscribeMetaTags = {
  title: 'Subscribe | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/subscribe',
};

export const dashboardMetaTags = {
  title: 'Dashboard | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/subscribe',
};

export const wishListMetaTags = {
  title: 'Wish List | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/subscribe',
};

export const accountMetaTags = {
  title: 'Account | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/account',
};

export const helpMetaTags = {
  title: 'Help | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/account',
};
