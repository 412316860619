import React from 'react';
import { StyledCourseOverviewCard, StyledOverviewSection } from './styled';
import ReactHtmlParser from 'react-html-parser';

const CourseOverviewCard = ({ descriptionFull, keyTopics }) => (
  <StyledCourseOverviewCard>
    <StyledOverviewSection>
      <h3>Overview</h3>
      {ReactHtmlParser(descriptionFull)}
    </StyledOverviewSection>
    <StyledOverviewSection>
      <h3>Key Topics</h3>
      <p>{ReactHtmlParser(keyTopics)}</p>
    </StyledOverviewSection>
  </StyledCourseOverviewCard>
);

export default CourseOverviewCard;
