import styled from 'styled-components';
import { color, multiply, spacer } from '../../../helpers/style';

export const StyledPlatform = styled.div`
  box-shadow: none;
  border-radius: 5px;
  border: solid 1px #d8d5d5;
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 2rem;
  padding: ${multiply(spacer, 1.5)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 400;
    font-size: 1.1rem;
    color: ${color('slate')};
  }
`;
