import React from 'react';
import styled, { css } from 'styled-components';
import heartIcon from '../../../public/static/image/icons/heart.svg';
import heartFilled from '../../../public/static/image/icons/heartFilled.svg';
import { color } from '../../../helpers/style';

export const StyledCourseCard = styled(({ hover, border, ...props }) => <div {...props} />)`
  text-decoration: none;
  border-radius: 5px;
  background: white;
  transition: all 0.7s ease;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 1rem;
  position: relative;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background-color: #fff;
  transition: all 0.4s ease-in-out;

  @media (min-width: 480px) {
    margin-bottom: 2rem;
  }

  a {
    text-decoration: none;
  }

  ${({ border }) =>
    border &&
    css`
      box-shadow: none;
      border: solid 1px #d8d5d5;
    `}

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        box-shadow: -1px 10px 19px 0px rgba(0, 0, 0, 0.5);
        transition: all 0.4s ease-in-out;
        cursor: pointer;
      }
    `}
`;

export const StyledCourseTitle = styled.h3`
  margin: 0;
  line-height: 24px;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${color('blueRise')};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 1rem;
`;

export const StyledCourseMeta = styled.p`
  display: flex;
  justify-content: space-between;
  color: ${color('slate')};
`;

export const StyledCourseMetaItem = styled.small`
  font-size: 0.9rem;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ full }) =>
    !full &&
    css`
      -webkit-line-clamp: 3;
    `}

  font-weight: 400;
`;

export const StyledCourseImage = styled(({ bordershadow, ...props }) => <img {...props} alt="Course" />)`
  width: 100%;
  border-radius: 5px 5px 0 0;
  position: relative;

  ${({ bordershadow }) =>
    bordershadow &&
    css`
      border-radius: 5px 5px 0 0;
    `}
`;

export const StyledCourseLikeButton = styled(({ courseLiked, ...props }) => <button {...props} />)`
  border: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 28px;
  width: 30px;
  color: #2d2d2d;
  border: none;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 10px 8px 8px;
  cursor: default;
  background: url(${({ courseLiked }) => (courseLiked ? `${heartFilled}` : `${heartIcon}`)});
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: all;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    background: url(${heartFilled});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const StyledCourseTags = styled.div`
  padding: 0rem 1.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCourseTag = styled.span`
  background-color: #ececec;
  border-color: #ececec;
  padding: 2px 8px 3px;
  color: grey;
  font-size: 0.8rem;
  line-height: 24px;
  border-radius: 12px;
  transition: all 0.2s ease-in;
  cursor: initial;
  margin-right: 5px;
  margin-bottom: 5px;

  &:hover {
    background: #0166bb;
    color: white;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  a {
    width: 100%;
    padding: 0.8rem 1rem;
    font-size: 0.85rem;
    line-height: 20px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    font-weight: 500;
    letter-spacing: 0px;
    background: ${color('blueSea')};
    &:hover {
      background: ${color('mariner')};
    }
  }
`;

export const StyledCourseContent = styled.div`
  padding: 1.5rem 1.5rem;
`;

export const StyledCourseSeperator = styled.div`
  width: 100%;
  background: #ececec;
  height: 1.5px;
`;

export const StyledCourseRating = styled.div`
  line-height: 22px;
  font-weight: 400;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;

  div {
    margin-left: 0.5rem;
  }
`;

export const StyledCourseRatingNumber = styled.span`
  color: ${color('gray')};
  font-size: 0.9rem;
`;
