import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyCQ8ZuSfjOPSZxDKumrGjzLb86yaUjTwf0',
  authDomain: 'learnstability-firebase.firebaseapp.com',
  databaseURL: 'https://learnstability-firebase.firebaseio.com',
  projectId: 'learnstability-firebase',
  storageBucket: 'learnstability-firebase.appspot.com',
  messagingSenderId: '304046796425',
  appId: '1:304046796425:web:b0282185454b1b6a36e058',
  measurementId: 'G-82CSWLD9QQ',
};

firebase.initializeApp(config);

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;
  const userRef = firestore.doc(`users/${userAuth.uid}`);
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });

      firebase.auth().currentUser.sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      });
    } catch (error) {
      console.log('error creating user', error.message);
    }
  }

  return userRef;
};

export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
  const collectionRef = firestore.collection(collectionKey);

  const batch = firestore.batch();
  objectsToAdd.forEach(obj => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};

export const convertCollectionsSnapshotToMap = collections => {
  const transformedCollection = collections.docs.map(doc => {
    const { title, items } = doc.data();

    return {
      routeName: encodeURI(title.toLowerCase()),
      id: doc.id,
      title,
      items,
    };
  });

  return transformedCollection.reduce((accumulator, collection) => {
    accumulator[collection.title.toLowerCase()] = collection;
    return accumulator;
  }, {});
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const getCourseReviewsById = courseId => {
  try {
    const result = firestore
      .collection('reviews')
      .where('courseId', '==', courseId)
      .limit(10)
      .get()
      .then(snapshot => snapshot.docs.map(doc => doc.data()));
    return result;
  } catch {
    console.log('ERROR');
  }
};

export const getCourseReviews = () => {
  try {
    const result = firestore
      .collection('reviews')
      .get()
      .then(snapshot => snapshot.docs.map(doc => doc.data()));
    return result;
  } catch {
    console.log('Error');
  }
};

export const removeLikedCourse = (userId, courseId) => {
  firestore
    .collection('users')
    .doc(userId)
    .update({
      savedCourses: firebase.firestore.FieldValue.arrayRemove(courseId),
    });
};

export const addLikedCourse = (userId, courseId) => {
  console.log('inMethod', userId);
  firestore
    .collection('users')
    .doc(userId)
    .update({
      savedCourses: firebase.firestore.FieldValue.arrayUnion(courseId),
    });
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;

// addCollectionAndDocuments('courses', [
//   {
//     id: '1',
//     title: 'The Complete JavaScript Course 2020: Build Real Projects!',
//     description:
//       'Master JavaScript with the most complete course! Projects, challenges, quizzes, JavaScript ES6+, OOP, AJAX, Webpack',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.com/course/the-complete-javascript-course/',
//     slug: 'the-complete-javascript-course',
//     image: 'https://i.udemycdn.com/course/480x270/851712_fc61_5.jpg',
//     author: 'Jonas Schmedtmann',
//     authorImage: 'https://i.udemycdn.com/user/100x100/7799204_2091_5.jpg',
//     tags: ['javascript', 'the complete', 'the complete javascript course'],
//     descriptionFull:
//       '<p>This is a truly complete JavaScript course, that goes beyond what other JavaScript courses out there teach you. I will take you from a complete JavaScript beginner to an advanced developer. You will not just learn the JavaScript language itself, you will also learn how to program. How to solve problems. How to structure and organize code using common JavaScript patterns.<p><p>Come with me on a journey with the goal of truly understanding the JavaScript language. And I explain everything on the way with great detail! You will learn "why" something works in JavaScript, not just "how". Because in the modern JavaScript world of today, you need more than just knowing how something works. You need to debug code, you need to understand code, you need to be able to think about code.</p>',
//     keyTopics:
//       '<ul><li>JavasScript</li><li>Hoisting</li><li>DOM</li><li>Prototypical Inheritance</li><li>Closures</li><li>ES6 / ES2015</li><li>NPM</li><li>Webpack</li><li>Babel & ES6 Modules</li><li>HTML & CSS</li><li>’this’</li></ul>',
//   },
//   {
//     id: '2',
//     title: 'JavaScript Understanding the Weird Parts',
//     description:
//       "An advanced JavaScript course for everyone! Scope, closures, prototypes, 'this', build your own framework, and more.",
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.com/course/understand-javascript/',
//     slug: 'understand-javascript',
//     image: 'https://i.udemycdn.com/course/480x270/364426_2991_5.jpg',
//     author: 'Anthony Alicea',
//     authorImage: 'https://i.udemycdn.com/user/100x100/2467626_f2e0.jpg',
//     tags: ['javascript', 'wierd parts', 'Anthony Alicea'],
//     descriptionFull:
//       '<p>In this course you will gain a deep understanding of Javascript, learn how Javascript works under the hood, and how that knowledge helps you avoid common pitfalls and drastically improve your ability to debug problems. You will find clarity in the parts that others, even experienced coders, may find weird, odd, and at times incomprehensible. You will learn the beauty and deceptive power of this language that is at the forefront of modern software development today.<p> <p>This course will cover such advanced concepts as objects and object literals, function expressions, prototypical inheritance, functional programming, scope chains, function constructors (plus new ES6 features), immediately invoked function expressions (IIFEs), call, apply, bind, and more.</p>',
//     keyTopics:
//       '<ul> <li>JavaScript</li><li>ES6</li><li>Objects</li><li>Scope Chains</li><li>Function Constructors</li><li>IFFEs</li><li>Functional Programming</li><li>Call, Apply, Bind</li><li>Frameworks & Libraries</li><li>Types & Operators</li></ul>',
//   },
//   {
//     id: '3',
//     title: 'Modern JavaScript From The Beginning',
//     description: 'Learn and build projects with pure JavaScript (No frameworks or libraries)',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.com/course/modern-javascript-from-the-beginning/',
//     slug: 'modern-javascript-from-the-beginning',
//     image: 'https://i.udemycdn.com/course/480x270/1463348_52a4_2.jpg',
//     author: 'Anthony Alicea',
//     authorImage: 'https://i.udemycdn.com/user/100x100/21681922_4513_5.jpg',
//     tags: ['javascript', 'modern javascript', 'modern javascript from the beginning'],
//     descriptionFull:
//       '<p>This course teaches you how to write clean and effective JavaScript code without using frameworks and libraries. This is a front to back JavaScript course for absolutely everybody. We start with the basic fundamentals and work our way to advanced programming WITHOUT relying on frameworks or libraries at all. You will learn a ton of pure JavaScript, whether you are a beginner or an established JS programmer.</p><p>You will learn various different topics including, Basics and fundamentals (data types, conditionals, loops, arrays etc) to Dom Manipulation, OOP, Async JS, ES2015+ and JavaScript patterns to help you become a well rounded knowledgeable developer.<p><p>Key projects include building a Task List with Local Storage, a Loan Calculator along with a fully functioning Microposts application that uses CRUD for REST API with Webpack & Babel<p></p>',
//     keyTopics:
//       '<ul><li>JavaScript</li><li>ES2015+</li><li>Dom Manipulations</li><li>OOP</li><li>JavaScript Patterns</li><li>Local Storage & Session Storage</li><li>Regex</li><li>Ajax & XHR</li><li>Fetch API</li><li>Async Await</li></ul>',
//   },
//   {
//     id: '4',
//     title: 'Advanced JavaScript Concepts',
//     description: 'Learn modern advanced JavaScript practices and be in the top 10% of JavaScript developers',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.com/course/advanced-javascript-concepts/',
//     slug: 'advanced-javascript-concepts',
//     image: 'https://i.udemycdn.com/course/480x270/1501104_967d_11.jpg',
//     author: 'Andrei Neagoie',
//     authorImage: '',
//     tags: ['javascript', 'advanced javascript concepts'],
//     descriptionFull:
//       "<p>With this course you are going to learn beyond just the basics like most online courses. You won't just learn patterns, techniques and best practices. You are going to understand the 'why' of the toughest part of the language, to the point that when you get asked any question about Javascript in an interview or in a meeting, you will be able to explain concepts that would truly make people see that you are a senior javascript programmer.</p><p>This course is the accumulation of years in the field, and combining the best resources, tools, and tutorials out there to create the ultimate JavaScript course that will teach you everything you need to know to be considered a Senior Javascript Developer.</p>",
//     keyTopics:
//       '<ul><li>Javascript Engine</li><li>Interpreter, Compiler, JIT Compiler</li><li>Writing Optimized Code</li><li>Call Stack + Memory Heap</li><li>Stack Flow + Memory Leaks</li><li>Garbage Collection</li><li>Node.js</li><li>ES6, ES7, ES8, ES9 features</li><li>Single Threaded Model</li><li>Execution Context</li><li>Lexical Environment</li><li>Scope Chain</li><li>Hoisting</li></ul>',
//   },
//   {
//     id: '5',
//     title: 'Fullstack Advanced React & GraphQL',
//     description:
//       'With a focus on modern JavaScript and real world application, this course is a start-to-finish way to learn Full Stack Advanced React, GraphQL, Node.js and more.',
//     platform: 'Wes Bos',
//     purchaseUrl: 'https://advancedreact.com/',
//     slug: 'fullstack-advanced-react-and-graphql',
//     image:
//       'https://firebasestorage.googleapis.com/v0/b/learnstability-firebase.appspot.com/o/courseimages%2Ffullstack.png?alt=media&token=6b734f69-2b5c-41d7-9594-58075e0e4bdb',
//     author: 'Wes Bos =',
//     authorImage: '',
//     tags: ['javascript', 'graphql', 'react', 'apollo', 'prisma'],
//     descriptionFull:
//       '<p>In this course you will learn how to build a real Fullstack Application using React, GraphQL, Apollo and Prisma. The course is broken down into 10 different modules that all come together as you build the Sick Fits clothing store from the ground up.<p><p>During the course you will learn key topics including how to correctly authenticate users, how routing works on the client site along with how to correctly handle payments in a secure way using Stripe.</p>',
//     keyTopics:
//       '<ul><li>React (NextJS)</li><li>Apollo</li><li>Styled Components</li><li>Prisma DB</li><li>JWT</li><li>Jest</li><li>Enzyme</li><li>Error Handling</li><li>Deployment (Heroku & Now.sh)<li></ul>',
//   },
//   {
//     id: '6',
//     title: 'Learn JavaScript With Codeacademy',
//     description: "Learn the JavaScript fundamentals you'll need for front-end or back-end development.",
//     platform: 'Codeacademy',
//     purchaseUrl: 'https://www.codecademy.com/learn/introduction-to-javascript',
//     slug: 'introduction-to-javascript',
//     image: 'https://i.udemycdn.com/course/480x270/851712_fc61_5.jpg',
//     author: 'Codacademy',
//     authorImage: '',
//     tags: ['javascript', 'codeacademy'],
//     descriptionFull: '',
//     keyTopics: '',
//   },
//   {
//     id: '7',
//     title: 'The Modern JavaScript Bootcamp',
//     description:
//       'Learn JavaScript by building real-world apps. Includes 3 real-world projects, 80 programming challenges, and ES6/ES7!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/vuejs-2-the-complete-guide/',
//     slug: 'modern-javascript-bootcamp',
//     image: 'https://i.udemycdn.com/course/480x270/1470810_a8b0.jpg',
//     author: 'Andrew Mead',
//     authorImage: '',
//     tags: ['javascript'],
//     descriptionFull: '',
//     keyTopics: '',
//   },
//   {
//     id: '8',
//     title: 'Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)',
//     description:
//       'Vue.js is an awesome JavaScript Framework for building Frontend Applications! VueJS mixes the Best of Angular + React!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/vuejs-2-the-complete-guide/',
//     slug: 'vuejs-2-the-complete-guide',
//     image: 'https://i.udemycdn.com/course/480x270/995016_ebf4.jpg',
//     author: 'Maximilian Schwarzmüller',
//     authorImage: '',
//     tags: ['javascript', 'vuejs', 'vue', 'vuex'],
//     descriptionFull:
//       "<p>In this course you will learn how to build real world Frontend application using VueJs and Vuex all the Way from Small and Simple Ones up to Large Enterprise-level ones. You will understand the theory behind Vue.js and use it in Real Projects</p><p>You may know Angular 2 and ReactJS, well, VueJS combines the Best of both Frameworks and makes building anything from small Widgets to big, Enterprise-Level Apps a Breeze and a whole lot of Fun! And if you don't know the two mentioned Frameworks: That's fine, too, this Course does not expect any knowledge of any other Frontend Framework - you will learn it all throughout this Course!<p>",
//     keyTopics:
//       '<ul><li>Vue JS</li><li>Vuex</li><li>Dom Interaction</li><li>JavaScript</li><li>Development Environment & Workflows</li><li>Forms</li><li>Transitions</li><li>Deployment</li><li>ES6</li><li>Animations</li></ul>',
//   },
//   {
//     id: '9',
//     title: 'Understanding TypeScript - 2020 Edition',
//     description:
//       "Don't limit the Usage of TypeScript to Angular! Learn the Basics, its Features, Workflows and how to use it!",
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/vuejs-2-the-complete-guide/',
//     slug: 'understanding-typescript',
//     image: 'https://i.udemycdn.com/course/480x270/947098_02ec.jpg',
//     author: 'Maximilian Schwarzmüller',
//     authorImage: '',
//     tags: ['typescript', 'javascript', 'types'],
//     descriptionFull: '',
//     keyTopics: '',
//   },
//   {
//     id: '10',
//     title: 'Angular Essentials (Angular 2+ with TypeScript)',
//     description:
//       'The most efficient way to dive into Angular 2+ if you got limited time or need to refresh the basics!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/vuejs-2-the-complete-guide/',
//     slug: 'angular-essentials-angular-2-angular-4-with-typescript',
//     image: 'https://i.udemycdn.com/course/480x270/1282020_b5b9.jpg',
//     author: 'Maximilian Schwarzmüller',
//     authorImage: '',
//     tags: ['typescript', 'javascript', 'angular', 'angular2'],
//     descriptionFull:
//       "<p>Learning Angular never is a wrong choice and with this course, it's more correct than it ever was before! This course gives you the fastest and most efficient way of diving into Angular. If you don't want or can't take my Complete Guide on Angular or you want to get a feeling for Angular before doing so, this course is for you! It's also your #1 choice if you already know Angular but want to have resource for refreshing your basic Angular knowledge as fast as possible.<p><p>Whilst this course is much shorter, it gives you all the core insights into Angular you need. It focuses on the heart of Angular, the most important features Angular offers you and even shows you how to build a complete Angular app!<p>",
//     keyTopics:
//       '<ul><li>Angular 2</li><li>Typescript</li><li>JavaScript</li><li>CLI</li><li>RxJS</li><li>Binding & Passing Data</li><li>Debugging</li><li>APIs</li><li>Routing</li><li>Forms</li><li>HTTP Requests</li><li>Code Optimisation</li></ul>',
//   },
//   {
//     id: '11',
//     title: "Typescript: The Complete Developer's Guide [2020]",
//     description:
//       'Master Typescript by learning popular design patterns and building complex projects.  Includes React and Express!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/typescript-the-complete-developers-guide',
//     slug: 'typescript-the-complete-developers-guide',
//     image: 'https://i.udemycdn.com/course/480x270/2337318_abfd_4.jpg',
//     author: 'Stephen Grider',
//     authorImage: '',
//     tags: ['typescript', 'javascript', 'react', 'express'],
//     descriptionFull:
//       '<p>This course helps you gain a key understanding of what Typescript is along with how to apply Typescript on real world projects. The course contains key areas including what is a is a type system along with the different design patters that you are likely to experience when using Typescript.<p><p>During the course you will learn why Composition vs Inheritance is the most mis-understood topic in the JavaScript community along with building a custom front-end framework from scratch that you can use in place of React or Angular on your own personal projects.<p><p>You will also learn how to structure React and Redux applications with Typescript and how to skip the documentation of third party libraries by reading the type definition files correctly.<p>',
//     keyTopics:
//       '<ul><li>Typescript</li><li>React</li><li>JavaScript</li><li>Decorators</li><li>Types</li><li>Typescript Setup </li><li>Design Patterns</li><li>Reusable Code</li><li>Boilerplates</li></ul>',
//   },
//   {
//     id: '12',
//     title: 'The Complete 2020 Web Development Bootcamp',
//     description:
//       'Become a full-stack web developer with just one course. HTML, CSS, Javascript, Node, React, MongoDB and more!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/typescript-the-complete-developers-guide',
//     slug: 'the-web-developer-bootcamp',
//     image: 'https://i.udemycdn.com/course/480x270/1565838_e54e_11.jpg',
//     author: 'Colt Steele',
//     authorImage: '',
//     tags: ['web development', 'javascript', 'web development bootcamp', 'bootcamp', 'html', 'css', 'react'],
//     descriptionFull:
//       "<p>This is one of the only courses you need to learn to code and become a full-stack web developer. With over 12,000 ratings and a 4.8 average, my Web Development course is one of the HIGHEST RATED courses in the history of Udemy!<p><p>With over 50+ hours this course is one of the most comprehensive ways to learn web development from scratch even if you have <strong>zero</strong> programming experience.<p><p>We'll take you step-by-step through engaging video tutorials and teach you everything you need to know to succeed as a web developer. The course includes over 50 hours of HD video tutorials and builds your programming knowledge while making real-world websites and web apps.</p>",
//     keyTopics:
//       '<ul><li>From End Web Development</li><li>HTML 5</li><li>CSS3</li><li>JavaScript ES6</li><li>DOM Manipulation </li><li>jQuery</li><li>Bash Command Line</li><li>Git, Gitbhub and Version Control</li><li>Backend Web Development</li><li>Node.js</li><li>NPM</li><li>Rest</li><li>APIs</li><li>Databases</li></ul>',
//   },
//   {
//     id: '13',
//     title: 'The Complete Web Developer Course 2.0',
//     description:
//       'Learn Web Development by building 25 websites and mobile apps using HTML, CSS, Javascript, PHP, Python, MySQL & more!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/typescript-the-complete-developers-guide',
//     slug: 'the-complete-web-developer-course-2',
//     image: 'https://i.udemycdn.com/course/480x270/1254420_f6cb_4.jpg',
//     author: 'Rob Percival',
//     authorImage: '',
//     tags: ['web development', 'javascript', 'web development bootcamp', 'php', 'html', 'css', 'react'],
//     descriptionFull:
//       '<p>In this course you will learn how to build websites and mobile applications using some of the standard web technologies including HTML, CSS, JavaScript, PHP, Python and more. The course is broken down into 15 key sections each covering key concepts to help you start building new websites quickly.</p><p>During the course you will build your own responsive websites using Twitter Bootstrap, and Wordpress and learn smart ways to integrate with third party APIs including Google Maps. The Complete Web Developer Course 2.0 also comes bundled with 30.5 hours on demand video, with over 143 supporting articles.</p>',
//     keyTopics:
//       '<ul><li>How does the web work?</li><li>Setting up your (free) hosting</li><li>HTML</li><li>CSS</li><li>JavaScript</li><li>jQuery</li><li>Wordpress</li><li>Responsive Design</li><li>Putting it all together: Making a Twitter clone</li><li>Bonus Chapter: How to make $10,000 while learning to code</li></ul>',
//   },
//   {
//     id: '14',
//     title: 'Understanding TypeScript - 2020 Edition',
//     description:
//       "Don't limit the Usage of TypeScript to Angular! Learn the Basics, its Features, Workflows and how to use it!",
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/understanding-typescript/',
//     slug: 'understaing-typescript-2020-edition',
//     image: 'https://img-a.udemycdn.com/course/480x270/947098_02ec.jpg',
//     author: 'Maximilian Schwarzmüller',
//     authorImage: '',
//     tags: ['typescript', 'Typescript', 'ts'],
//     descriptionFull:
//       "<p>Learn what TypeScript is, why it really is a powerful Addition to JavaScript, what its Features are and how to use it! And whilst doing so, also understand what you're actually doing in your Angular 2+ Code.</p><p>This Course takes you from the very Basics and its most important Feature (Types!) to the Point where you're able to use TypeScript in any of your Projects. ReactJS Projects included!</p><p>In this Course, we'll not stop after the Basics. You will learn about more advanced Features and also how to set up Workflows with  TypeScript. This includes a TypeScript-only Workflow as well as a Webpack Workflow.</p><p>Watching Videos is a great Way to Learn. And to a lot of Students, it's the best Way. If you also want to practice the Things you learn, this Course offers you Exercises (and Solutions) in many of the Course Modules.</p>",
//     keyTopics:
//       '<ul><li>How does the web work?</li><li>Setting up your (free) hosting</li><li>HTML</li><li>CSS</li><li>JavaScript</li><li>jQuery</li><li>Wordpress</li><li>Responsive Design</li><li>Putting it all together: Making a Twitter clone</li><li>Bonus Chapter: How to make $10,000 while learning to code</li></ul>',
//   },
//   {
//     id: '15',
//     title: 'Advanced CSS and Sass',
//     description:
//       'The most advanced and modern CSS course on the internet: master flexbox, CSS Grid, responsive design, and so much more.',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/advanced-css-and-sass/',
//     slug: 'advanced-css-and-sass',
//     image: 'https://img-a.udemycdn.com/course/480x270/1026604_790b_2.jpg',
//     author: 'Jonas Schmedtmann',
//     authorImage: '',
//     tags: ['Advanced css and sass', 'CSS', 'Sass', 'css grid', 'flexbox'],
//     descriptionFull:
//       "<p>Welcome to Advanced CSS and Sass, the most advanced, the most modern, and the most complete CSS course on the internet. It's everything you want in an advanced CSS course, and more.</p><p>You will learn complex CSS animations, advanced responsive design techniques, flexbox layouts, Sass, CSS architecture, fundamental CSS concepts, and so much more. Like in all my courses, you will build beautiful and carefully designed projects, that will make you proud of yourself and leave you ready to build complex projects on your own.</p><p>And I packed so much content into this course, that no matter if you're just starting out, or if you're already an experienced front-end developer, there is definitely gonna be something for you in this course.</p>",
//     keyTopics:
//       '<ul><li>Sass</li> <li>Advanced CSS animations</li><li>CSS Grid</li><li>Modern responsive design</li><li>Responsive images in HTML and CSS</li><li>SVG images in HTML and CSS</li><li> Videos in HTML and CSS</li><li>Flexbox layouts</li><li>The NPM ecosystem</li></ul>',
//   },
//   {
//     id: '16',
//     title: 'Modern HTML & CSS From The Beginning',
//     description: 'Build modern responsive websites & UIs with HTML5, CSS3 & Sass! Learn Flex & CSS Grid',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/modern-html-css-from-the-beginning',
//     slug: 'modern-html-css-from-the-beginning',
//     image: 'https://img-a.udemycdn.com/course/480x270/2153774_bef0_4.jpg',
//     author: 'Brad Traversy',
//     authorImage: '',
//     tags: ['html', 'css', 'flexbox', 'responsive design', 'css grid', 'emmet'],
//     descriptionFull:
//       '<p>This course is for both beginners and seasoned developers that want to learn how to build responsive websites and user interfaces with modern HTML5 and CSS3+ technologies like Flexbox and CSS Grid as well as the Sass pre-compiler. This course includes hours of both learning & studying sections along with real life projects. Stop having to rely on frameworks like Bootstrap for your user interface and learn how to create your own layouts and utility classes to build custom responsive websites and app UIs.</p><p>The first few sections are tailored for beginners so even if you have never built anything before, you will learn all of the basics. If you already have experience with basic HTML & CSS, just move to section 3 or 4 and get started.</p>',
//     keyTopics:
//       '<ul><li>How Websites Work</li><li>Visual Studio Code Setup, Shortcuts, Live Server & Emmet</li><li>HTML5 Semantic Layout</li><li>CSS Fundamentals</li><li>Responsive Design With Media Queries</li><li>CSS Units - rem, em, vh, vw, etc</li><li>Flexbox</li><li>CSS Grid</li><li>CSS Variables</li><li>Sass Pre-compiler</li><li>Website Deployment to Shared Hosting & Netlify With Git</li></ul>',
//   },
//   {
//     id: '17',
//     title: 'Build Responsive Real World Websites with HTML5 and CSS3',
//     description:
//       'The easiest way to learn modern web design, HTML5 and CSS3 step-by-step from scratch. Design AND code a huge project.',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/design-and-develop-a-killer-website-with-html5-and-css3',
//     slug: 'design-and-develop-a-killer-website-with-html5-and-css3',
//     image: 'https://img-a.udemycdn.com/course/480x270/1498364_dc68_3.jpg',
//     author: 'Jonas Schmedtmann',
//     authorImage: '',
//     tags: ['html', 'css', 'flexbox', 'responsive design', 'css grid', 'emmet'],
//     descriptionFull:
//       '<p>Together we hand-code a beautiful and responsive landing page for a fictional company that I made up just for the course. Step-by-step, you will learn more and more HTML5 and CSS3 features, from beginner to advanced. These are the latest web technologies, used by every website in the world. And we even added some jQuery to the mix.</p><p>This huge project will teach you all the real-world skills to build real-world HTML5 and CSS3 websites. This will allow you to build any website you can imagine... From complete scratch.</p>',
//     keyTopics:
//       '<ul><li>HTML5</li><li>CSS3</li><li>jQuery</li><li>Images</li><li>Fonts</li><li>Animations</li><li>SEO</li><li>Deployment</li><ul>',
//   },
//   {
//     id: '18',
//     title: 'Learn HTML5 Programming From Scratch',
//     description: 'A Complete HTML5 Programming Course for Beginners',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/learn-html5-programming-from-scratch/',
//     slug: 'learn-html5-programming-from-scratch',
//     image: 'https://img-a.udemycdn.com/course/480x270/41295_dd3f_8.jpg',
//     author: 'Eduonix-Tech',
//     authorImage: '',
//     tags: ['html', 'html5', 'css', 'flexbox', 'responsive design', 'css grid', 'emmet'],
//     descriptionFull:
//       '<p>In our course you will start with basic HTML tags. You will learn how to create HTML websites and use CSS in your work before we start discussing HTML5. We do not assume any prior knowledge so we go through the concepts from the beginners point of view. In our HTML5 lectures we start with new tags and forms.</p><p>We then gradually move to advance topics like Drag and Drop and Multimedia. We follow it with difficult topics like Geolocation, WebStorage and Application cache. This course creates easy to grasp examples for each of the concepts and by the end of it you will be able to understand the complete picture rather than just bits and pieces of the technology.</p>',
//     keyTopics:
//       '<ul><li>HTML5</li><li>JavaScript</li><li>Git</li><li>Drag and Drop</li><li>Web Storage</li><li>Responsive Design</li><li>Flexbox</li><li>CSS Animations</li><li>Positioning</li></ul>',
//   },
//   {
//     id: '19',
//     title: 'Complete Python Bootcamp: Go from zero to hero in Python 3',
//     description:
//       'Learn Python Programming from Beginner to Advanced Level! Go from Zero to Hero in Python with Hundreds of Examples!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/complete-python-programming-course-beginner-to-advanced/',
//     slug: 'complete-python-programming-course-beginner-to-advanced/',
//     image: 'https://img-a.udemycdn.com/course/480x270/1343594_c09c_28.jpg',
//     author: 'Oracle Master Training',
//     authorImage: '',
//     tags: ['python', 'django', 'backend', 'oop', 'databases'],
//     descriptionFull:
//       '<p>This is one of the most comprehensive, yet straight-forward, course for the Python programming language! Whether you have never programmed before, already know basic syntax, or want to learn about the advanced features of Python, this course is for you! In this course we will teach you Python 3. (Note, we also provide older Python 2 notes in case you need them)</p><p>With over 100 lectures and more than 20 hours of video this comprehensive course leaves no stone unturned! This course includes quizzes, tests, and homework assignments as well as 3 major projects to create a Python project portfolio!</p>',
//     keyTopics:
//       '<ul><li>Command Line Basics</li><li>Installing Python</li><li>Dictionaries</li><li>Tuples</li><li>Number Data Types</li><li>Debugging and Error Handling</li><li>Built-in Functions</li><li>Object Oriented Programming</li><li>Polymorphism</li><li>Inheritance</li></ul>',
//   },
//   {
//     id: '20',
//     title: 'Learn Python Programming Masterclass',
//     description:
//       'This Python For Beginners Course Teaches You The Python Language  Fast.  Includes Python Online Training With Python 3',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/python-the-complete-python-developer-course/',
//     slug: 'python-the-complete-python-developer-course',
//     image: 'https://img-a.udemycdn.com/course/480x270/629302_8a2d_2.jpg',
//     author: 'Tim Buchalka',
//     authorImage: '',
//     tags: ['python', 'django', 'backend', 'oop', 'databases'],
//     descriptionFull:
//       '<p>This course is aimed at complete beginners who have never programmed before, as well as existing programmers who want to increase their career options by learning Python.</p> <p>The fact is, Python is one of the most popular programming languages in the world – Huge companies like Google use it in mission critical applications like Google Search.And Python is the number one language choice for machine learning, data science and artificial intelligence. To get those high paying jobs you need an expert knowledge of Python, and that’s what you will get from this course.</p><p>By the end of the course you’ll be able to apply in confidence for Python programming jobs. And yes, this applies even if you have never programmed before. With the right skills which you will learn in this course, you can become employable and valuable in the eyes of future employers.</p>',
//     keyTopics:
//       '<ul><li>Running Python Code</li><li>Object Oriented Pyton</li><li>Dictionaries and Sets</li><li>Generators</li><li>Packages</li><li>Binary Number System</li><li>Input and Output</li><li>Program Flow Control</li><li>Syntax</li></ul>',
//   },
//   {
//     id: '21',
//     title: 'The Python Mega Course: Build 10 Real World Applications',
//     description:
//       'A complete Python course for both beginners and intermediates! Master Python 3 by making 10 amazing Python apps.',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/the-python-mega-course/',
//     slug: 'the-python-mega-course',
//     image: 'https://img-a.udemycdn.com/course/480x270/692188_9da7_13.jpg',
//     author: 'Ardit Sulce',
//     authorImage: '',
//     tags: ['python', 'django', 'backend', 'oop', 'databases'],
//     descriptionFull:
//       '<p>The Python Mega Course is the most practical course you will find on the web today. So far, over 140,000 students have used the course to learn Python programming and to build real-world applications in Python 3. You will learn how to build Python apps during this course, even if you know nothing about programming. You will start from scratch and progressively build up your skills by creating some awesome Python programs ranging from webcam object detection apps, to data collector web apps that query data from SQL databases, to data visualization dashboards on the browser.</p><p>The course follows a modern-teaching approach where students learn by doing. You will start Python from scratch by creating simple programs first. Once you learn the basics, you will then start with the fun part, which is building 10 real-world applications.</p>',
//     keyTopics:
//       '<ul><li>Data visualization</li><li>Object-Oriented Programming</li><li>Web mapping</li><li>Web scraping</li><li>Computer vision</li><li>Database applications</li><li>Desktop applications</li></ul>',
//   },
//   {
//     id: '22',
//     title: 'Python for Finance: Investment Fundamentals & Data Analytics',
//     description:
//       'Learn Python Programming and Conduct Real-World Financial Analysis in Python - Complete Python Training',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/python-for-finance-investment-fundamentals-data-analytics',
//     slug: 'python-for-finance-investment-fundamentals-data-analytics',
//     image: 'https://img-a.udemycdn.com/course/480x270/1035472_23ce_5.jpg',
//     author: '365 Careers',
//     authorImage: '',
//     tags: ['python', 'django', 'backend', 'oop', 'databases', 'finance'],
//     descriptionFull:
//       '<p>We are proud to present Python for Finance: Investment Fundamentals and Data Analytics – one of the most interesting and complete courses we have created so far. It took our team slightly over four months to create this course, but now, it is ready and waiting for you. </p><p>If you are a complete beginner and you know nothing about coding, don’t worry! We start from the very basics. The first part of the course is ideal for beginners and people who want to brush up on their Python skills. And then, once we have covered the basics, we will be ready to tackle financial calculations and portfolio optimization tasks. </p>',
//     keyTopics:
//       '<ul><li>Monte Carlo simulations </li><li>Derivatives and type of derivatives </li><li>Measuring a regression’s explanatory power with R^2 </li><li>Alpha and Beta coefficients </li><li>Regression analysis </li><li>Diversifiable and non-diversifiable risk </li><li>Correlation between stocks</li><li>Risk of stock portfolios </li><li>Rate of return of stock portfolios </li></ul>',
//   },
//   {
//     id: '23',
//     title: 'Python and Django Full Stack Web Developer Bootcamp',
//     description: 'Learn to build websites with HTML , CSS , Bootstrap , Javascript , jQuery , Python 3 , and Django!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/python-and-django-full-stack-web-developer-bootcamp',
//     slug: 'python-and-django-full-stack-web-developer-bootcamp',
//     image: 'https://img-a.udemycdn.com/course/480x270/822444_a6db.jpg',
//     author: 'Jose Portilla',
//     authorImage: '',
//     tags: ['python', 'django', 'backend', 'oop', 'databases'],
//     descriptionFull:
//       "<p>Welcome to the Python and Django Full Stack Web Developer Bootcamp! In this course we cover everything you need to know to build a website using Python, Django, and many more web technologies!</p><p>We will teach you the latest technologies for building great web applications with Python 3 and Django! But we don't just teach that, we also teach the Front End technologies you need to know, including HTML, CSS, and Javascript. This course can be your one stop shop for everything you need! It will serve as a useful reference for many of your questions as you begin your journey in becoming a web developer!</p>",
//     keyTopics:
//       '<ul><li>HTML5</li><li>CSS3</li><li>Python</li><li>Django Basics</li><li>Django Templates</li><li>Django Forms</li><li>Django Admin Customization</li><li>REST APIs</li><li>User Authentication</li></ul>',
//   },
//   {
//     id: '24',
//     title: 'The Complete Node.js Developer Course',
//     description: 'Learn Node.js by building real-world applications with Node, Express, MongoDB, Jest, and more!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/the-complete-nodejs-developer-course-2',
//     slug: 'the-complete-nodejs-developer-course-2',
//     image: 'https://img-a.udemycdn.com/course/480x270/922484_52a1_8.jpg',
//     author: 'Rob Percival',
//     authorImage: '',
//     tags: ['node', 'nodejs', 'node js', 'npm', 'es6', 'javascript', 'mongodb'],
//     descriptionFull:
//       '<p>The Complete Node.js Developer Course covers the fundamentals of Node before diving deep into great tools like Express, Mongoose, and MongoDB.</p> <p>The entire course is based around a single goal: Turning you into a professional Node developer capable of developing, testing, and deploying real-world production applications.</p><p>From the very start you’ll be programming every project and working through challenges that I’ve designed to reinforce what you’ve learned. This will give you the hands-on experience necessary to be able to create and launch your own project once you’re done.</p>',
//     keyTopics:
//       '<ul><li>Node.js</li><li>Npm</li><li>Asynchronous programming</li><li>ES6/ES7</li><li>MongoDB</li><li>Express</li><li>Socket.IO</li><li>JWT Authentication</li><li>Mongoose</li><li>File and image uploads</li><li>Application deployment with Heroku</li></ul>',
//   },
//   {
//     id: '25',
//     title: 'Learn and Understand NodeJS',
//     description:
//       'Dive deep under the hood of NodeJS. Learn V8, Express, the MEAN stack, core Javascript concepts, and more.',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/understand-nodejs/',
//     slug: 'understand-nodejs',
//     image: 'https://img-a.udemycdn.com/course/480x270/461160_8d87_6.jpg',
//     author: 'Anthony Alicea',
//     authorImage: '',
//     tags: ['node', 'nodejs', 'node js', 'npm', 'es6', 'javascript', 'mongodb'],
//     descriptionFull:
//       "<p>In this course you will gain a deep understanding of Node, learn how NodeJS works under the hood, and how that knowledge helps you avoid common pitfalls and drastically improve your ability to debug problems.</p><p>In this course we'll look at how the C++ written V8 Javascript engine works and how NodeJS uses it to expand the abilities of Javascript. You'll learn how to structure your code for reuse and to be easier to understand, manage, and expand using modules and understand how modules really work.</p><p>You'll learn how asynchronous code works in Node and the Node event loop, as well as how to use the event emitter, streams, buffers, pipes, and work with files. We'll see how that leads to building a web server in Node.</p>",
//     keyTopics:
//       '<ul><li>V8 The JavaScript Engine</li><li>The Node Core</li><li>Modules</li><li>Events and Event Emitter</li><li>Async Code </li><li>Event Loop</li><li>HTTP</li><li>NPM</li><li>Express</li><li>JSON & Databases</li><li>MEAN Stack</li></ul>',
//   },
//   {
//     id: '26',
//     title: 'Learn and Understand NodeJS',
//     description:
//       'Master Node JS, build REST APIs with Node.js, GraphQL APIs, add Authentication, use MongoDB, SQL & much more!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/nodejs-the-complete-guide',
//     slug: 'nodejs-the-complete-guide',
//     image: 'https://img-a.udemycdn.com/course/480x270/1879018_95b6.jpg',
//     author: 'Maximilian Schwarzmüller',
//     authorImage: '',
//     tags: ['node', 'nodejs', 'node js', 'npm', 'es6', 'javascript', 'mongodb', 'graphql', 'express', 'rest'],
//     descriptionFull:
//       "<p>Node.js developers are in high demand and the language is used for everything from traditional web apps with server-side rendered views over REST APIs all the way up to GraphQL APIs and real-time web services. Not to mention its applications in build workflows for projects of all sizes.</p><p>This course will teach you all of that! From scratch with zero prior knowledge assumed. Though if you do bring some knowledge, you'll of course be able to quickly jump into the course modules that are most interesting to you.</p>",
//     keyTopics:
//       '<ul><li>Node.js Basics & Basic Core Modules</li><li>Parsing Requests & Sending Responses</li><li>Rendering HTML Dynamically</li><li>Using Express.js</li><li>File Up- and Download</li><li>Using the Model-View-Controller (MVC) Pattern</li><li>Using Node.js with SQL (MySQL) and Sequelize</li><li>Using Node.js with NoSQL (MongoDB) and Mongoose</li><li>User Authentication and Authorization</li><li>Validating User Input</li><li>Authentication in REST APIs</li></ul>',
//   },
//   {
//     id: '27',
//     title: 'Node with React: Fullstack Web Development',
//     description: 'Build and deploy fullstack web apps with NodeJS, React, Redux, Express, and MongoDB.',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/node-with-react-fullstack-web-development',
//     slug: 'node-with-react-fullstack-web-development',
//     image: 'https://img-a.udemycdn.com/course/480x270/1254420_f6cb_4.jpg',
//     author: 'Stephen Grider',
//     authorImage: '',
//     tags: ['node', 'nodejs', 'node js', 'npm', 'es6', 'javascript', 'mongodb', 'graphql', 'express', 'rest'],
//     descriptionFull:
//       "<p>In this course we'll build one massive web application that profiles the advanced features of React, Redux, Express, and Mongo.  By putting each concept into a real app, you'll get a better idea of when to use each unique and powerful feature.</p><p>Build a large feedback-collection app. This mega app will include the full gamut of features, including everything from authentication to email handling.  You'll learn how to build an app that can be used to send mass emails to a big list of users for the purpose of collecting feedback.  It's my goal to ensure you understand each feature we build into this app so you can apply them to your own personal or professional projects in the future.</p>",
//     keyTopics:
//       '<ul><li>React</li><li>Node</li><li>Redux Form</li><li>Google OAuth Authentication</li><li>API key handling techniques</li><li>Mongo DB</li><li>Custom Build Landing Pages</li><li>Automated Emails</li><li>Stripe API</li></ul>',
//   },
//   {
//     id: '28',
//     title: 'React - The Complete Guide',
//     description:
//       'Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/react-the-complete-guide-incl-redux/',
//     slug: 'react-the-complete-guide-incl-redux',
//     image: 'https://img-a.udemycdn.com/course/480x270/1362070_b9a1_2.jpg',
//     author: 'Maximilian Schwarzmüller',
//     authorImage: '',
//     tags: ['react', 'redux', 'react hooks', 'nextjs'],
//   },
//   {
//     id: '29',
//     title: 'Modern React with Redux [2020 Update]',
//     description: 'Master React v16.6.3 and Redux with React Router, Webpack, and Create-React-App.  Includes Hooks!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/react-redux/',
//     slug: 'react-redux',
//     image: 'https://img-a.udemycdn.com/course/480x270/705264_caa9_11.jpg',
//     author: 'Stephen Grider',
//     authorImage: '',
//     tags: ['react', 'redux', 'react hooks', 'nextjs'],
//   },
//   {
//     id: '29',
//     title: 'The Complete React Native + Hooks Course',
//     description:
//       'Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/the-complete-react-native-and-redux-course',
//     slug: 'the-complete-react-native-and-redux-course',
//     image: 'https://img-a.udemycdn.com/course/480x270/959700_8bd2_11.jpg',
//     author: 'Stephen Grider',
//     authorImage: '',
//     tags: ['react', 'redux', 'react hooks', 'react native', 'nextjs'],
//   },
//   {
//     id: '30',
//     title: 'The Complete React Developer Course',
//     description:
//       'Learn how to build and launch React web applications using React, Redux, Webpack, React-Router, and more!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/react-2nd-edition',
//     slug: 'react-2nd-edition',
//     image: 'https://img-a.udemycdn.com/course/480x270/1286908_1773_5.jpg',
//     author: 'Andrew Mead',
//     authorImage: '',
//     tags: ['react', 'redux', 'react hooks', 'nextjs'],
//   },
//   {
//     id: '31',
//     title: 'Vue JS Essentials with Vuex and Vue Router',
//     description: 'Have limited time to learn Vue, Vuex, and Vue Router?  Take this course and learn Vue in 11 hours!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/vue-js-course/',
//     slug: 'vue-js-course/',
//     image: 'https://img-a.udemycdn.com/course/480x270/1605992_39af_2.jpg',
//     author: 'Stephen Grider',
//     authorImage: '',
//     tags: ['vue', 'vuex', 'vue router'],
//   },
//   {
//     id: '32',
//     title: 'Nuxt.js - Vue.js on Steroids',
//     description:
//       'Build highly engaging Vue JS apps with Nuxt.js. Nuxt adds easy server-side-rendering and a folder-based config approach.',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/nuxtjs-vuejs-on-steroids',
//     slug: 'nuxtjs-vuejs-on-steroids',
//     image: 'https://img-a.udemycdn.com/course/480x270/1523224_60cb.jpg',
//     author: 'Maximilian Schwarzmüller',
//     authorImage: '',
//     tags: ['vue', 'vuex', 'vue router'],
//   },
//   {
//     id: '33',
//     title: 'Build Web Apps with Vue JS 2 & Firebase',
//     description: 'Learn Vue JS & Firebase by creating & deploying dynamic web apps (including Authentication).',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.comcourse/course/build-web-apps-with-vuejs-firebase',
//     slug: 'build-web-apps-with-vuejs-firebase',
//     image: 'https://img-a.udemycdn.com/course/480x270/1639836_a03e_3.jpg',
//     author: 'Shaun Pelling',
//     authorImage: '',
//     tags: ['vue', 'vuex', 'vue router'],
//   },
//   {
//     id: '34',
//     title: 'Vue JS 2.0 - Mastering Web Apps',
//     description:
//       'Create the VueJS 2.0 apps you always wanted. Build 5 apps, learn development secrets, VueRouter, Vuex, & more!',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.com/course/course/vue-web-apps',
//     slug: 'vue-web-apps',
//     image: 'https://img-a.udemycdn.com/course/480x270/1165096_e5af_3.jpg',
//     author: 'David Joseph Katz',
//     authorImage: '',
//     tags: ['vue', 'vuex', 'vue router'],
//   },
//   {
//     id: '35',
//     title: 'Gatsby Tutorial and Projects Course',
//     description: 'Create Blazingly Fast Websites with Gatsby',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.com/course/course/gatsby-tutorial-and-projects-course',
//     slug: 'gatsby-tutorial-and-projects-course',
//     image: 'https://img-a.udemycdn.com/course/480x270/2176554_3198_6.jpg',
//     author: 'John Smilga',
//     authorImage: '',
//     tags: ['gatsby', 'static site', 'static sites'],
//   },
//   {
//     id: '36',
//     title: 'The Gatsby Masterclass',
//     description: 'Build blazing-fast, feature-rich, and overall stunning React apps with the Gatsby framework',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.com/course/course/the-gatsby-masterclass',
//     slug: 'the-gatsby-masterclass',
//     image: 'https://img-a.udemycdn.com/course/480x270/2159160_5be6_19.jpg',
//     author: 'Reed Barger',
//     authorImage: '',
//     tags: ['gatsby', 'static site', 'static sites'],
//   },
//   {
//     id: '37',
//     title: 'Universal React with Next.js - The Ultimate Guide',
//     description: 'Learn how to make awesome server-rendered React apps with Next.js',
//     platform: 'Udemy',
//     purchaseUrl: 'https://www.udemy.com/course/course/universal-react-with-nextjs-the-ultimate-guide',
//     slug: 'universal-react-with-nextjs-the-ultimate-guide',
//     image: 'https://img-a.udemycdn.com/course/480x270/2019358_7ded_3.jpg',
//     author: 'Reed Barger',
//     authorImage: '',
//     tags: ['next', 'next js', 'server rendering', 'server side'],
//   },
// ]);

// author;
// ('Jonas Schmedtmann');
// authorImage;
// ('https://i.udemycdn.com/user/100x100/7799204_2091_5.jpg');
// description;
// ('Master JavaScript with the most complete course! Projects, challenges, quizzes, JavaScript ES6+, OOP, AJAX, Webpack');
// id;
// ('2');
// image;
// ('https://i.udemycdn.com/course/480x270/851712_fc61_5.jpg');
// platform;
// ('Udemy');
// platformLogo;
// ('https://firebasestorage.googleapis.com/v0/b/learnstability-firebase.appspot.com/o/courseimages%2Fudemy.svg?alt=media&token=73c8befc-d146-49ff-9e05-f3ee36a874c1');
// price;
// ('$10.99');
// purchaseUrl;
// ('https://www.udemy.com/course/the-complete-javascript-course/');
// slug;
// ('the-complete-javascript-course');
// tags;
// 0;
// ('javascript');
// title;
// ('The Complete JavaScript Course 2020');
