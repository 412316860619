import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import courseReducer from './courses/course.reducer';
import reviewReducer from './reviews/review.reducer';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  courses: courseReducer,
  reviews: reviewReducer,
});

export default persistReducer(persistConfig, rootReducer);
