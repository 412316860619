import styled from 'styled-components';
import { color, multiply, spacer } from '../../../helpers/style';

export const StyledCourseReview = styled.section`
  background: ${color('soapstone')};
  padding: ${multiply(spacer, 2)} 0;

  @media (min-width: 480px) {
    padding: ${multiply(spacer, 3)} 0;
  }
`;
