import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { coursesMetaTags } from '../../../helpers/constants';
import {
  CourseSearchWrapper,
  StyledCoursesContainer,
  StyledCoursesMeta,
  StyledSearchWrapper,
  StyledSpinner,
} from './styled';
import { removeLikedCourse, addLikedCourse } from '../../../firebase/firebase.utils';
import { fetchCoursesStart } from '../../../redux/courses/course.actions';
import { fetchReviewsStart } from '../../../redux/reviews/review.actions';
import { withRouter } from 'react-router';
import Navbar from '../../../components/Navigation';
import Layout from '../../../components/Layout';
import CourseCard from '../../../components/Course/CourseCard';
import SearchForm from '../../../components/Forms/SearchForm';
import Error from '../../../components/Error';
import Dropdown from '../../../components/UI/Dropdown';
import { calculateReviewData } from '../helper';

const CourseSearch = ({ location, fetchCoursesStart, courses, fetchReviewsStart, loading, error, currentUser }) => {
  const queryVal = queryString.parse(location.search).q;
  const courseLimit = 12;
  const [searchTerm, setSearchTerm] = useState(queryVal);
  const [courseLiked, setCourseLiked] = useState();
  const [likedCourses, setLikedCourses] = useState([]);

  useEffect(() => {
    fetchCoursesStart(searchTerm, courseLimit);
    // fetchReviewsStart();
    currentUser && setLikedCourses(currentUser.savedCourses);
    window.scrollTo(0, 0);
  }, [fetchCoursesStart, fetchReviewsStart, searchTerm, currentUser]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // });

  // function handleScroll() {
  //   if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
  //     fetchCoursesStart(searchTerm, courseLimit + 6);
  //   }
  // }

  const toggleCourseWishList = (e, courseId) => {
    e.preventDefault();
    e.stopPropagation();

    if (likedCourses.includes(courseId)) {
      setLikedCourses(likedCourses.filter(e => e !== courseId));
      removeLikedCourse(currentUser.id, courseId);
    } else {
      try {
        addLikedCourse(currentUser.id, courseId);
        setCourseLiked(courseId);
        setLikedCourses([...likedCourses, courseId]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderCourses = courses =>
    courses.map((course, index) => {
      const reviewScores = course.reviewScores && Object.values(course.reviewScores);
      return (
        <Col md={4} key={index}>
          <CourseCard
            currentCourse={course}
            toggleCourseWishList={toggleCourseWishList}
            courseLiked={courseLiked}
            likedCourses={likedCourses}
            reviewCount={reviewScores ? reviewScores.length : 0}
            reviewAverage={calculateReviewData(reviewScores)}
            hover
            bordershadow
          />
        </Col>
      );
    });

  return (
    <Layout metaTags={coursesMetaTags}>
      <Navbar />
      <CourseSearchWrapper>
        <StyledCoursesContainer>
          <StyledSearchWrapper>
            <SearchForm searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </StyledSearchWrapper>

          <StyledCoursesMeta>
            {courses && <span>{courses.length} courses found.</span>}
            <Dropdown />
          </StyledCoursesMeta>

          <Row>
            <Col xs={12}>{courses && !courses.length && <span>No Courses Found!</span>}</Col>
            {loading ? <StyledSpinner /> : courses && renderCourses(courses)}
          </Row>
        </StyledCoursesContainer>

        {error && <Error error={error.message} />}
      </CourseSearchWrapper>
    </Layout>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  courses: state.courses.courses,
  reviews: state.reviews.reviews,
  loading: state.courses.isFetching,
  error: state.courses.error,
});

const mapDispatchToProps = dispatch => ({
  fetchCoursesStart: (searchTerm, limitIncrease) => dispatch(fetchCoursesStart(searchTerm, limitIncrease)),
  fetchReviewsStart: () => dispatch(fetchReviewsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseSearch));
