import styled from 'styled-components';
import { Button } from 'reactstrap';

export const StyledIcon = styled.img`
  transition: color 0.15s ease-in-out;
  width: 20px;
  height: 20px;
`;

export const StyledButton = styled(Button)`
  display: ${({ block }) => (block ? 'flex' : 'inline-flex')};
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 400;
  font-family: 'Graphik', sans-serif;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: ${({ outline }) => (outline ? '2px solid #525252' : '0px')};
  user-select: none;
  background-color: ${({ outline }) => (outline ? 'transparent' : '#1c85e8')};
  color: ${({ outline }) => (outline ? '#525252' : '#ffffff')};
  font-size: 0.8rem;
  box-shadow: none;
  padding: 0.5rem 0.9rem;

  a {
    text-decoration: none;
    color: white;
    letter-spacing: 0;
    box-shadow: none;
  }

  @media (min-width: 768px) {
    width: auto;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
    background: ${({ outline }) => (outline ? '#525252' : '  #194971')};
    box-shadow: none;
    color: white;
  }

  &:disabled {
    box-shadow: none;
    pointer-events: none;
  }
`;
