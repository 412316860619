import React from 'react';
import { StyledPlatform } from './styled';
import Button from '../../../components/UI/Button';
import udemyLogo from '../../../public/static/image/platforms/udemy.svg';

const CoursePlatformCard = ({ currentCourse: { platform, purchaseUrl } }) => {
  const renderPlatformLogo = () => {
    switch (platform) {
      case 'Udemy':
        return <img src={udemyLogo} alt={platform} />;
      case 'Mangoes':
      case 'Papayas':
        console.log('Mangoes and papayas are $2.79 a pound.');
        // expected output: "Mangoes and papayas are $2.79 a pound."
        break;
      default:
        return <span>{platform}</span>;
    }
  };

  return (
    <StyledPlatform>
      {renderPlatformLogo()}
      <Button href={`${purchaseUrl}`}>Purchase Course</Button>
    </StyledPlatform>
  );
};

export default CoursePlatformCard;
