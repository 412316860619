import styled from 'styled-components';

export const StyledCourseOverviewCard = styled.div`
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.7s ease;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 5px;
  border: solid 1px #d8d5d5;
  background-color: #fff;
  padding: 1.5rem 1rem;

  @media (min-width: 480px) {
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1.3rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 26px;
    &:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  ul {
    padding: 0rem 1rem;
    margin-bottom: 0;
  }
`;

export const StyledOverviewSection = styled.div`
  &:not(:last-child) {
    padding-bottom: 2rem;
  }
`;
