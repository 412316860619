import styled from 'styled-components';
import FormWrapper from '../../styles/Form';

export const LoginFormWrapper = styled(FormWrapper)`
  width: 100%;
  @media (min-width: 480px) {
    min-width: 450px;
  }

  .new-account {
    span {
      font-weight: 400;
    }
  }
`;

export const StyledButtonGroup = styled.div`
  button:first-child {
    margin-bottom: 1rem;
  }
  img {
    margin-right: 1rem;
  }
`;
