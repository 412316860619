import styled from 'styled-components';
import FormWrapper from '../../styles/Form';

export const StyledSignUpForm = styled(FormWrapper)`
  width: 100%;
  @media (min-width: 480px) {
    min-width: 450px;
  }

  .new-account {
    span {
      font-weight: 400;
    }
  }
`;
