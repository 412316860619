import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Container, Col } from 'reactstrap';
import { coursesMetaTags } from '../../../helpers/constants';
import { StyledCourseReview } from './styled';
import { getCourseReviewsById } from '../../../firebase/firebase.utils';
import Navbar from '../../../components/Navigation';
import Layout from '../../../components/Layout';
import CourseCard from '../../../components/Course/CourseCard';
import Spinner from '../../../components/UI/Spinner';
import LeaveReviewForm from '../../../components/Forms/LeaveReviewForm';
import CourseReviewCard from '../../../components/Course/CourseReviewCard';
import CourseDetailsCard from '../../../components/Course/CourseDetailsCard';
import CourseOverviewCard from '../../../components/Course/CourseOverviewCard';
import CourseChartDataCard from '../../../components/Course/CourseChartDataCard';
import CoursePlatformCard from '../../../components/Course/CoursePlatformCard';
import Social from '../../../components/Social';
import Alert from '../../../components/UI/Alert';
import { useMediaQuery } from 'react-responsive';
import { calculateReviewData } from '../helper';

const CourseReview = ({ courses, currentUser }) => {
  const [currentCourse, setCurrentCourse] = useState();
  const [courseReviews, setCourseReviews] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const getCurrentCourse = () => {
      const courseUrl = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      const matchingCourse = courses.filter(course => course.slug === courseUrl);

      // Get course by URL

      console.log(courses);
      setCurrentCourse(matchingCourse[0]);
      getCourseReviews(matchingCourse[0]);
    };

    courses && getCurrentCourse();
  }, [courses, currentCourse]);

  const getCourseReviews = async currentCourse => {
    const reviewData = await getCourseReviewsById(currentCourse.id);
    setCourseReviews(reviewData);
  };

  const toggleWriteReview = () => {
    setModalOpen(prev => !prev);
  };

  const renderCourseReviews = courseReviews =>
    courseReviews.map((review, index) => {
      return (
        <CourseReviewCard
          key={index}
          title={review.title}
          reviewerName={review.reviewerName}
          content={review.content}
          rating={review.rating}
        />
      );
    });

  const renderModalBody = () => (
    <LeaveReviewForm
      courseId={currentCourse.id}
      toggleWriteReview={toggleWriteReview}
      currentCourse={currentCourse}
      setSubmitted={setSubmitted}
      setError={setError}
      currentUser={currentUser}
    />
  );

  console.log(currentCourse);
  return (
    <Layout metaTags={coursesMetaTags}>
      <Navbar />
      {!courseReviews ? (
        <Spinner />
      ) : (
        <StyledCourseReview>
          <Container>
            {submitted && (
              <Alert color="success">Success! Your review has been submitted and will be added shortly.</Alert>
            )}
            {error && <Alert color="danger">There was an error fetching data, please refresh the page.</Alert>}
            <Row>
              <Col xs={{ size: 12, order: 'second' }} sm={{ size: 8, order: 'first' }}>
                {currentCourse.descriptionFull && currentCourse.keyTopics && (
                  <CourseOverviewCard
                    descriptionFull={currentCourse.descriptionFull}
                    keyTopics={currentCourse.keyTopics}
                  />
                )}

                <CourseChartDataCard
                  currentUser={currentUser}
                  toggleWriteReview={toggleWriteReview}
                  modalOpen={modalOpen}
                  renderModalBody={renderModalBody}
                  currentCourse={currentCourse}
                  calculateReviewData={calculateReviewData}
                  courseReviews={courseReviews}
                />

                {renderCourseReviews(courseReviews)}
              </Col>
              <Col xs={{ size: 12, order: 'first' }} sm={4}>
                {currentCourse && (
                  <Fragment>
                    <CourseCard currentCourse={currentCourse} border full />

                    <CourseDetailsCard
                      authorName="Tom Blaymire"
                      categories={['JavaScript', 'React']}
                      access="Full access on Mobile and TV "
                      verified="Verified course"
                      length="20 Hours on-demand video"
                      resources="76 downloadable resources"
                      certificate="Certificate of Completion"
                    />

                    {!isMobile && <CoursePlatformCard currentCourse={currentCourse} />}

                    {!isMobile && <Social title={currentCourse.title} />}
                  </Fragment>
                )}
              </Col>
            </Row>
          </Container>
        </StyledCourseReview>
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  courses: state.courses.courses,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(CourseReview);
